<template>
  <v-container>
    <v-row justify="center">
      <v-col class="text-center" md="6">
        <v-btn
          block
          color="warning"
          outlined
          @click="$router.push('/principal')"
          >volver</v-btn
        >
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col md="6" cols="12">
        <div elevation="5" color="info">
          <v-card-text class="text-center">
            <v-icon left>mdi-store</v-icon>Bodega Principal (capitania)
            <v-btn
              x-small
              color="success"
              @click="$router.push('/bodegaprincipal')"
              >entrar</v-btn
            >
            <p class="mb-0 pl-8">Ultimo Movimiento: 12/01/2024</p>
          </v-card-text>
        </div>
      </v-col>
      <v-col md="6" cols="12" align-self="center" justify-self="center">
        <div elevation="5" color="info" dark>
          <v-card-text class="text-center">
            <v-icon left>mdi-store</v-icon>Inventario Global de Compañia
            <v-btn
              x-small
              color="success"
              @click="$router.push('/inventarioglobal')"
              >entrar</v-btn
            >
            <p class="mb-0 pl-8">Ultimo Movimiento: 12/01/2024</p>
          </v-card-text>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col md="3">
        <h3>Voluntarios</h3>
        <v-text-field
          dense
          label="BUSCAR"
          v-model="buscar_personas"
        ></v-text-field>
        <v-data-table
          dense
          :headers="headers"
          :items="personas"
          class="elevation-5"
          sort-by="nSello"
          :search="buscar_personas"
          @click:row="consulta"
        >
          <template v-slot:[`item.name`]="{ item }">
            {{ item.name }} {{ item.fathersSurname }}
          </template>
        </v-data-table>
      </v-col>
      <v-col md="9">
        <h3>
          Detalle de elementos de cargo del voluntario:
          <span class="text-h5"
            >{{ personaConsultada.name }}
            {{ personaConsultada.fathersSurname }}
            {{ personaConsultada.mothersSurname }}</span
          >
        </h3>
        <v-text-field
          dense
          label="BUSCAR IMPLEMENTO"
          v-model="buscar_items"
        ></v-text-field>
        <v-data-table
          dense
          :headers="headers_detalle"
          :items="itemsPorPersona"
          class="elevation-5"
          sort-by="nSello"
          :search="buscar_items"
          item-key="id_asignacion"
        >
          <template v-slot:[`item.fecha_asignacion`]="{ item }">
            <span>{{
              $dayjs(item.fecha_asignacion).format("DD-MM-YYYY")
            }}</span>
          </template>
          <template v-slot:[`item.fecha_estado`]="{ item }">
            <span>{{ $dayjs(item.fecha_estado).format("DD-MM-YYYY") }}</span>
          </template>
          <template v-slot:[`item.nombre_tipo_item`]="{ item }">
            {{ item.nombre_tipo_item }} {{ item.nombre_subtipo }}
          </template>
          <template v-slot:[`item.nombre_tipo_item`]="{ item }">
            {{ item.nombre_tipo_item }} {{ item.nombre_subtipo }}
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      personas: [],
      personaConsultada: [],
      itemsPorPersona: [],
      buscar_personas: "",
      buscar_items: "",
      headers: [
        { text: "Nombre", value: "name" },
        { text: "accion", value: "accion" },
      ],
      headers_detalle: [
        { text: "Item", value: "type.name" },
        { text: "Marca", value: "make.name" },
        { text: "Modelo", value: "model.name" },
        { text: "Serie", value: "serial_number" },
        { text: "N°Interno", value: "internal_identifier" },
        { text: "Talla", value: "size" },
        { text: "Asignado", value: "fecha_asignacion" },
        //{ text: "Revisado", value: "fecha_estado" },
        { text: "Estado", value: "condition.condition" },
      ],
    };
  },

  firestore() {
    return {};
  },
  methods: {
    flattenObject: function (obj, parentKey = "") {
      return Object.keys(obj).reduce((acc, key) => {
        const newKey = parentKey ? `${parentKey}.${key}` : key;

        if (typeof obj[key] === "object" && obj[key] !== null) {
          Object.assign(acc, this.flattenObject(obj[key], newKey));
        } else {
          acc[newKey] = obj[key];
        }

        return acc;
      }, {});
    },
    consulta: function (data) {
      this.personaConsultada = data;
      const self = this
      axios.get(`${process.env.VUE_APP_BASE_URL}/api/firefighter/get/${data.id}`).then((response) => {
        //let xx = self.flattenObject(response.data.assigned_items);
        console.log(response.data[0].assigned_items);
        this.itemsPorPersona = response.data[0].assigned_items
      });
    },
  },

  computed: {},
  mounted() {
    axios.get(`${process.env.VUE_APP_BASE_URL}/api/firefighter/getAll`).then((response) => {
      this.personas = response.data;
    });
    /*  axios
      .get('http://localhost:3000/itemsporpersona')
      .then((response) => {
        this.itemsPorPersona = response.data
        console.log(response.data);
      })  */
  },
};
</script>
